<!-- Yup schema defined in ~/utils/schema/cgu-schema.js -->
<script setup>
  const props = defineProps({
    error: { type: String }
  })
</script>

<template>
  <FormField id="cgu" name="cgu" :error="props.error" type="checkbox" required :uncheckedValue="false" :value="true">
    <template v-slot:label>
      <label for="cgu">
        Je certifie avoir lu et accepté les
        <NuxtLink class="text-viking-400 underline duration-300 hover:text-viking-500" to="/conditions-generales" target="_blank">CGU</NuxtLink>
        de Profil Public.
      </label>
    </template>
  </FormField>
</template>
